import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private sanitizer: DomSanitizer) {}

  handleIFrameUrl(path: string): SafeResourceUrl {
    if (path.startsWith('{origin}')) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        path.replace(
          '{origin}',
          window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '')
        )
      );
    } else if (path.startsWith('/')) {
      window.location.assign(window.location.href.replace(/\/#.*/g, '') + path);
      return undefined;
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(path);
    }
  }
}
