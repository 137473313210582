import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StateService } from '../../services/state-service/state.service';
import { ErrorInfo } from '../../models/error-info.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../components/modal/modal.component';
import { SpinnerOverlayService } from '../../services/spinner-overlay/spinner-overlay.service';

export enum HttpStatusCode {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private stateService: StateService,
    private modalService: NgbModal,
    private spinnerService: SpinnerOverlayService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.show();

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.hide();
          }
        },
        (error) => {
          this.spinnerService.hide();
        }
      ),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.UNAUTHORIZED: {
            this.router.navigateByUrl('/authenticate').then();
            break;
          }
          case HttpStatusCode.FORBIDDEN: {
            this.router
              .navigateByUrl(
                this.stateService.getRedirectPathByAssessmentState(
                  (<ErrorInfo>error.error).state
                )
              )
              .then();
            break;
          }
          case HttpStatusCode.NOT_FOUND: {
            this.showErrorModal(
              this.errorModalTranslationKey(error)
            ).result.then((result) => {
              this.router.navigateByUrl('/welcome').then();
            });
            break;
          }
          case HttpStatusCode.INTERNAL_SERVER_ERROR: {
            this.router.navigateByUrl('/error').then();
            break;
          }
          default: {
            this.showErrorModal(this.errorModalTranslationKey(error));
            break;
          }
        }
        return throwError(error.message);
      })
    );
  }

  errorModalTranslationKey(error: HttpErrorResponse): string {
    if (error.error instanceof ErrorInfo) {
      return error.error.translationKey;
    } else {
      return 'rspModalGeneralBody';
    }
  }

  showErrorModal(modalBodyKey: string): NgbModalRef {
    let modal: NgbModalRef = this.modalService.open(ModalComponent, {});
    modal.componentInstance.modalBodyKey = modalBodyKey;
    modal.componentInstance.modalTitleKey = 'rspModalGeneralHeading';
    modal.componentInstance.modalButtonCloseKey = 'rspModalButtonClose';
    return modal;
  }
}
