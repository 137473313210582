import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserContextService } from '../user-context-service/user-context.service';
import { AssessmentService } from '../assessment-service/assessment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, tap } from 'rxjs/operators';
import { UrlConfigService } from '../url-config-service/url-config.service';
import { AssessmentState } from '../../models/assessment-wrapper.model';
import { SaveAssessmentModalComponent } from '../../dialogs/save-assessment-modal';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private userContextService: UserContextService,
    private assessmentService: AssessmentService,
    private modalService: NgbModal,
    private urlConfigService: UrlConfigService
  ) {}

  verifySign(assessmentId: string): Observable<any> {
    return this.http.get(
      this.urlConfigService.getAuthServiceVerifyUrl(assessmentId),
      { responseType: 'text' }
    );
  }

  logoutUser(
    forceLogout?: boolean,
    forceQuestionnaireSave?: boolean
  ): Observable<string> {
    let logoutRequest: Observable<string>;

    if (forceLogout) {
      logoutRequest = this.getLogoutPathForForcedLogout();
    } else if (forceQuestionnaireSave) {
      logoutRequest = this.syncQuestionnaire(true);
    } else if (this.assessmentService.getCurrentAssessmentWrapperId()) {
      logoutRequest = this.askSaveQuestion();
    } else {
      logoutRequest = this.getLogoutRequest();
    }

    return logoutRequest.pipe(
      tap((path) => {
        this.assessmentService.clearCurrentAssessmentWrapperId();
        this.userContextService.loggedInUser = null;
        this.cookieService.delete('isLoggedIn');
        this.cookieService.delete('lang');
        window.location.replace(path);
      })
    );
  }

  isUserLoggedIn(): boolean {
    return (
      !!this.cookieService.get('isLoggedIn') &&
      !!this.userContextService.loggedInUser
    );
  }

  getSignPath(assessmentWrapperId: string, lang: string): Observable<string> {
    return this.http.get(
      this.urlConfigService.getAuthServiceSignUrl(assessmentWrapperId, lang),
      { responseType: 'text' }
    );
  }

  getLoginPath(configurationId: string, lang: string): Observable<string> {
    return this.http.get(
      this.urlConfigService.getAuthServiceLoginUrl(configurationId, lang),
      { responseType: 'text' }
    );
  }

  refreshCepToken(assessmentId: string): Observable<any> {
    return this.http.get(
      this.urlConfigService.getAuthServiceTokenRefresh(assessmentId),
      {
        responseType: 'text',
      }
    );
  }

  refreshCepTokenAsPromise(assessmentId: string): Promise<null | void> {
    return new Promise<null | void>((resolve) => {
      this.http
        .get(this.urlConfigService.getAuthServiceTokenRefresh(assessmentId))
        .subscribe(() => {
          resolve();
        });
    });
  }

  private getLogoutRequest(
    assessmentWrapperId?: string,
    saveApplication?: boolean
  ): Observable<string> {
    let url: string = this.urlConfigService.getAuthServiceLogoutUrl();
    if (assessmentWrapperId) {
      url = `${url}?assessmentWrapperId=${assessmentWrapperId}&saveApplication=${
        saveApplication ? 'true' : 'false'
      }`;
    }
    return this.http.get(url, { responseType: 'text' });
  }

  private askSaveQuestion(): Observable<string> {
    return this.assessmentService
      .get(this.assessmentService.getCurrentAssessmentWrapperId())
      .pipe(
        switchMap((assessment) => {
          if (
            assessment.state === AssessmentState.STARTED ||
            assessment.state === AssessmentState.COMPLETED
          ) {
            return this.saveQuestionModal().pipe(
              switchMap((result) => {
                return this.syncQuestionnaire(result === 'save');
              })
            );
          } else {
            return this.getLogoutRequest();
          }
        })
      );
  }

  private getLogoutPathForForcedLogout(): Observable<string> {
    if (!this.assessmentService.getCurrentAssessmentWrapperId()) {
      return this.getLogoutRequest();
    }

    return this.assessmentService
      .get(this.assessmentService.getCurrentAssessmentWrapperId())
      .pipe(
        switchMap((assessment) =>
          assessment.state === AssessmentState.STARTED ||
          assessment.state === AssessmentState.COMPLETED
            ? this.syncQuestionnaire(false)
            : this.getLogoutRequest()
        )
      );
  }

  private saveQuestionModal(): Observable<any> {
    return this.modalService.open(SaveAssessmentModalComponent, {
      ariaLabelledBy: 'modal-title',
      centered: true,
    }).closed;
  }

  private syncQuestionnaire(save: boolean): Observable<string> {
    if (save) {
      return this.assessmentService.currentQuestionnaire.pipe(
        switchMap((q) => {
          return from(q.sync().finally()).pipe(
            switchMap((ignore) => {
              return this.logoutPathWithSaveQuestion(save);
            })
          );
        })
      );
    } else {
      return this.logoutPathWithSaveQuestion(save);
    }
  }

  private logoutPathWithSaveQuestion(save: boolean): Observable<string> {
    return this.getLogoutRequest(
      this.assessmentService.getCurrentAssessmentWrapperId(),
      save
    );
  }
}
