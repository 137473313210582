import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from './services/app-config-service/app-config.service';
import { ThemingService } from './services/theming-service/theming.service';
import { AutoLogoutServiceService } from './services/auto-logout-service/auto-logout-service.service';
import { first, switchMap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SpinnerOverlayService } from './services/spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  areTranslationsAvailable: boolean = false;

  constructor(
    private translate: TranslateService,
    titleService: Title,
    private appConfigService: AppConfigService,
    private themingService: ThemingService,
    public sanitizer: DomSanitizer,
    private cookieService: CookieService,
    private autoLogoutService: AutoLogoutServiceService,
    private router: Router,
    public spinnerOverlayService: SpinnerOverlayService
  ) {
    this.translate.onDefaultLangChange
      .pipe(first())
      .subscribe(() => (this.areTranslationsAvailable = true));

    this.themingService.loadSupportedTranslations().subscribe((langs) => {
      translate.addLangs(langs);
      translate.setDefaultLang(
        this.appConfigService.configData.defaultLanguage
      );

      this.autoLogoutService.initAutoLogoutDetection();

      let lang: string = this.determineLanguage(langs);
      cookieService.set('lang', lang);
      translate.use(lang);
      translate
        .get('rspPageTitle')
        .subscribe((next) => titleService.setTitle(next));
      this.translate.onLangChange
        .pipe(
          switchMap(() => {
            return this.translate.get('rspPageTitle');
          })
        )
        .subscribe((title) => {
          titleService.setTitle(title);
        });
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  determineLanguage(langs: string[]): string {
    if (
      this.cookieService.check('lang') &&
      langs.includes(this.cookieService.get('lang'))
    ) {
      return this.cookieService.get('lang');
    }

    return langs.includes(this.translate.getBrowserLang())
      ? this.translate.getBrowserLang()
      : this.translate.getDefaultLang();
  }
}
