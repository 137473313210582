import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { Subscription } from 'rxjs';
import { Router, RoutesRecognized } from '@angular/router';
import { AutoLogoutServiceService } from '../../services/auto-logout-service/auto-logout-service.service';
import { UrlConfigService } from '../../services/url-config-service/url-config.service';
import { AssessmentService } from '../../services/assessment-service/assessment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLanguageSwitchPermitted: boolean;
  availableLanguages: string[];
  currentLanguage: string;
  logoLeftSrc: string;
  logoRightSrc: string;
  timeToTimeout: Date;
  logoutTime: Date;
  progress: string;
  progressSub: Subscription;
  timeTillTimeoutSubscription: Subscription;
  showSaveAndLogoutButton: boolean;
  isNavbarMenuCollapsed: boolean = true;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private appConfigService: AppConfigService,
    private userContextService: UserContextService,
    private authService: AuthenticationService,
    private router: Router,
    private autoLogoutService: AutoLogoutServiceService,
    private urlConfigService: UrlConfigService,
    private assessmentService: AssessmentService
  ) {
    this.currentLanguage = this.translateService.currentLang;
    this.availableLanguages = this.translateService.getLangs();
    this.translateService.onLangChange.subscribe((newLanguageSelection) => {
      this.currentLanguage = newLanguageSelection.lang;
    });
    this.logoLeftSrc = this.getLeftLogoUrl();
    this.logoRightSrc = this.getRightLogoUrl();

    this.updateLogoutTimer(
      this.appConfigService.configData.secondsToAutoLogout * 1000
    );

    this.timeTillTimeoutSubscription = this.autoLogoutService.updatedTimeToTimeout.subscribe(
      (timeLeftTillTimeout) => {
        this.updateLogoutTimer(timeLeftTillTimeout);
      }
    );
  }

  ngOnInit(): void {
    this.progressSub = this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        if (data.state.root.firstChild.data.hasOwnProperty('progress')) {
          this.progress = data.state.root.firstChild.data['progress'];
        } else {
          this.progress = '0%';
        }

        if (
          data.state.root.firstChild.data.hasOwnProperty(
            'isLanguageSwitchPermitted'
          )
        ) {
          this.isLanguageSwitchPermitted =
            data.state.root.firstChild.data['isLanguageSwitchPermitted'];
        } else {
          this.isLanguageSwitchPermitted = false;
        }

        if (
          data.state.root.firstChild.data.hasOwnProperty(
            'isSaveAndLogoutButtonPermitted'
          )
        ) {
          this.showSaveAndLogoutButton =
            data.state.root.firstChild.data['isSaveAndLogoutButtonPermitted'];
        } else {
          this.showSaveAndLogoutButton = false;
        }
      }
    });
  }

  ngOnDestroy() {
    this.progressSub.unsubscribe();
    this.timeTillTimeoutSubscription.unsubscribe();
  }

  isLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }

  logout(forceQuestionnaireSave?: boolean): void {
    this.authService.logoutUser(false, !!forceQuestionnaireSave).subscribe();
  }

  changeLanguage(selectedLanguageCode: string): void {
    this.translateService.use(selectedLanguageCode);
    this.cookieService.set('lang', selectedLanguageCode);
  }

  hasGivenConsent(): boolean {
    return (
      this.cookieService.check('rsp_cookie_consent') &&
      Boolean(this.cookieService.get('rsp_cookie_consent'))
    );
  }

  giveConsent(): void {
    this.cookieService.set('rsp_cookie_consent', 'true');
  }

  loggedInMessage(): string {
    return this.userContextService.loggedInUser?.loggedInMessage || '';
  }

  private updateLogoutTimer(timeLeftInMs: number): void {
    this.timeToTimeout = new Date();
    this.timeToTimeout.setTime(timeLeftInMs);
  }

  private getLeftLogoUrl(): string {
    if (this.appConfigService.configData.api.themingService.logoPath?.left) {
      return this.urlConfigService.getThemingServiceLeftLogoUrl(
        this.appConfigService.configurationId
      );
    }

    return null;
  }

  private getRightLogoUrl(): string {
    if (this.appConfigService.configData.api.themingService.logoPath?.right) {
      return this.urlConfigService.getThemingServiceRightLogoUrl(
        this.appConfigService.configurationId
      );
    }

    return null;
  }
}
