<footer class="container-fluid footer">
  <div class="row rsp-container">
    <div id="footer-org" class="col-md-4 col-xs-12 px-0">
      <dl>
        <dt>
          {{ "rspFooterOrgLabel" | translate }}
        </dt>
        <dd>
          <p>
            {{ "rspFooterOrgNumber" | translate }}<br />
            {{ "rspFooterOrgNumber2" | translate }}
          </p>
        </dd>
      </dl>
    </div>
    <div id="footer-address" class="col-md-4 col-xs-12 px-0">
      <dl>
        <dt>
          {{ "rspFooterAddressLabel" | translate }}
        </dt>
        <dd>
          <p>
            {{ "rspFooterAddress1" | translate }}<br />
            {{ "rspFooterAddress2" | translate }}
          </p>
        </dd>
      </dl>
    </div>
    <div id="footer-phone-email" class="col-md-4 col-xs-12 px-0">
      <dl class="grid">
        <dt>
          {{ "rspFooterPhoneLabel" | translate }}
        </dt>
        <dd>
          {{ "rspFooterPhone" | translate }}
        </dd>
        <dt>
          {{ "rspFooterEmailLabel" | translate }}
        </dt>
        <dd>
          {{ "rspFooterEmail" | translate }}
        </dd>
        <dt>
          {{ "rspFooterAdditionalContactLabel" | translate }}
        </dt>
        <dd>
          {{ "rspFooterAdditionalContact" | translate }}
        </dd>
      </dl>
    </div>
  </div>
</footer>
