import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireNotificationModalComponent } from '../../dialogs/questionaire-notification-modal';
import {
  Questionnaire,
  QuestionnaireNotification,
} from '../../models/assessment-questionaire.model';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
})
export class AssessmentComponent implements OnDestroy {
  questionnaireUrl = 'src/vendor/reflex-cep-questionnaire2/questionnaire.js';
  activeAssessmentId = '';
  questionnaireConfig;
  readonly = false;
  isRtl = false;
  language = 'en';
  translations;
  paginationMode = 'Questionnaire';
  activePageId;
  applicationData;
  @ViewChild('questionnaireComponent') questionnaire: ElementRef;
  renewToken: Function;

  constructor(
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private modalService: NgbModal,
    private router: Router,
    private appConfig: AppConfigService,
    private authenticationService: AuthenticationService
  ) {
    this.activeAssessmentId = this.route.snapshot.paramMap.get('assessmentId');
    this.language = this.route.snapshot.paramMap.get('lang');
    this.translations = appConfig.cepTranslation;
    this.questionnaireConfig = appConfig.cepConfiguration;
    if (this.route.snapshot.paramMap.get('readonly')) {
      this.readonly = this.route.snapshot.paramMap.get('readonly') === 'true';
    }
    this.renewToken = (): Promise<null | void> => {
      return this.authenticationService.refreshCepTokenAsPromise(
        this.activeAssessmentId
      );
    };
  }

  ngOnDestroy() {
    this.assessmentService.publishQuestionnaire(undefined);
  }

  onNotification(notificationData: QuestionnaireNotification) {
    if (notificationData.type && notificationData.type === 'error') {
      if (notificationData.error?.status > 500) {
        this.router.navigate(['/error']);
      } else {
        const modalRef = this.modalService.open(
          QuestionnaireNotificationModalComponent,
          {
            ariaLabelledBy: 'modal-title',
            centered: true,
          }
        );
        modalRef.componentInstance.headline = notificationData.headline;
        modalRef.componentInstance.contentText = notificationData.messagetext;
        modalRef.componentInstance.messageType = notificationData.type;
      }
    }
  }

  onQuestionnaireInit() {
    if (this.questionnaire) {
      this.assessmentService.publishQuestionnaire(
        <Questionnaire>this.questionnaire.nativeElement
      );
    }
  }

  onAssessmentFinished() {
    this.assessmentService
      .completeAssessment(
        this.assessmentService.getCurrentAssessmentWrapperId()
      )
      .subscribe(() => {
        this.readonly = true;
        window.scroll(0, 0);
      });
  }

  editQuestionnaire() {
    this.readonly = false;
    window.scroll(0, 0);
  }

  goToConfirmPage() {
    this.router.navigate([
      '/confirm',
      this.activeAssessmentId,
      { lang: this.language },
    ]);
  }
}
