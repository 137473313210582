import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { Attribute, ProductResult } from '../../models/product-result.model';
import { Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { UrlConfigService } from '../../services/url-config-service/url-config.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit {
  productResults: ProductResult[] = [];
  showResultToUser: boolean;
  docUrl: string = '';

  constructor(
    private assessmentService: AssessmentService,
    private appConfig: AppConfigService,
    private urlConfigService: UrlConfigService
  ) {
    this.showResultToUser = this.appConfig.featureToggles.showResultToUser;
  }

  ngOnInit(): void {
    const currentAssessmentWrapperId = this.assessmentService.getCurrentAssessmentWrapperId();
    if (currentAssessmentWrapperId) {
      const subscription: Subscription = this.assessmentService
        .getResult(currentAssessmentWrapperId)
        .pipe(
          tap((productResults) => {
            this.productResults = productResults;
            this.docUrl = this.urlConfigService.getDocumentServiceDocUrl(
              currentAssessmentWrapperId
            );
          }),
          switchMap(() => {
            return this.assessmentService.postProcess(
              currentAssessmentWrapperId
            );
          })
        )
        .subscribe();

      setTimeout(() => {
        subscription.unsubscribe();
      }, 5000);
    }
  }

  showDetails(attributes: Attribute[]): boolean {
    const validAttributeIds: string[] = [
      'EMLoading',
      'Text',
      'ILoading',
      'Exclusion',
      'PremiumLoading',
      'AncillaryEMLoading',
      'FlatExtraPremium',
      'PostponeDuration',
    ];
    return !!attributes.find((e) => validAttributeIds.includes(e.id));
  }

  showAttribute(attribute: Attribute): boolean {
    let validIds: string[] = [
      'EMLoading',
      'ILoading',
      'PremiumLoading',
      'AncillaryEMLoading',
      'FlatExtraPremium',
      'PostponeDuration',
    ];
    return validIds.includes(attribute.id);
  }

  hasDuration(attributes: Attribute[]): boolean {
    return !!attributes.find((e) => e.id === 'FlatExtraLoading');
  }
}
