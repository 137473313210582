import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from '../../components/welcome/welcome.component';
import { AuthenticateComponent } from '../../components/authenticate/authenticate.component';
import { InitialInformationComponent } from '../../components/initial-information/initial-information.component';
import { ConsentComponent } from '../../components/consent/consent.component';
import { AssessmentComponent } from '../../components/assessment/assessment.component';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { ResultComponent } from '../../components/result/result.component';
import { SignComponent } from '../../components/sign/sign.component';
import { RouteGuard } from './route-guard';
import { ConsentGuard } from './consent-guard';
import { AssessmentStateGuard } from './assessment-state-guard';
import { LoggedInGuard } from './auth-guard';
import { ErrorComponent } from '../../components/error/error.component';

export const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
    data: {
      progress: '0%',
      isLanguageSwitchPermitted: true,
      isSaveAndLogoutButtonPermitted: false,
    },
  },
  {
    path: 'authenticate',
    component: AuthenticateComponent,
    data: {
      progress: '14%',
      isLanguageSwitchPermitted: true,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [LoggedInGuard],
  },
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
  {
    path: 'consent',
    component: ConsentComponent,
    data: {
      progress: '29%',
      isLanguageSwitchPermitted: true,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [RouteGuard],
  },
  {
    path: 'initial-information',
    component: InitialInformationComponent,
    data: {
      progress: '43%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [RouteGuard, ConsentGuard],
  },
  {
    path: 'assessment/:assessmentId',
    component: AssessmentComponent,
    data: {
      progress: '57%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: true,
    },
    canActivate: [RouteGuard, ConsentGuard, AssessmentStateGuard],
  },
  {
    path: 'confirm/:assessmentId',
    component: ConfirmComponent,
    data: {
      progress: '71%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [RouteGuard, ConsentGuard, AssessmentStateGuard],
  },
  {
    path: 'sign/:assessmentId',
    component: SignComponent,
    data: {
      progress: '86%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [RouteGuard, ConsentGuard, AssessmentStateGuard],
  },
  {
    path: 'result',
    component: ResultComponent,
    data: {
      progress: '100%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: false,
    },
    canActivate: [RouteGuard, ConsentGuard, AssessmentStateGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: {
      progress: '0%',
      isLanguageSwitchPermitted: false,
      isSaveAndLogoutButtonPermitted: false,
    },
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
