<h1>
  {{ (!readonly ? "rspAssessmentHeading" : "rspReviewHeading") | translate }}
</h1>
<form class="mx-1 rsp-assessment" [class.review-form]="readonly">
  <cep-web-questionnaire
    #questionnaireComponent
    *ngIf="activeAssessmentId"
    [assessmentId]="activeAssessmentId"
    [config]="questionnaireConfig"
    [readOnly]="readonly"
    [language]="language"
    [translations]="translations"
    [paginationMode]="paginationMode"
    [activePageId]="activePageId"
    [arsp-containerpplication]="applicationData"
    [rtl]="isRtl"
    [authorizationHandler]="renewToken"
    (activePageIdChange)="activePageId = $event.detail"
    (notification)="onNotification($event.detail)"
    (initializedChange)="onQuestionnaireInit()"
    (assessmentFinished)="onAssessmentFinished()"
    (applicationChange)="applicationData = $event.detail"
  >
    <div slot="before">
      <cep-web-question-reminder [offset]="54"></cep-web-question-reminder>
      <cep-web-blocked-pagination-alert></cep-web-blocked-pagination-alert>
      <cep-web-breadcrumb-pagination></cep-web-breadcrumb-pagination>
      <cep-web-prev-next-pagination></cep-web-prev-next-pagination>
    </div>
    <div slot="after">
      <cep-web-full-page-pagination></cep-web-full-page-pagination>
      <cep-web-questionnaire-legend></cep-web-questionnaire-legend>
    </div>
  </cep-web-questionnaire>
  <div *ngIf="!readonly" class="d-flex justify-content-end">
    <cep-web-submit-button></cep-web-submit-button>
  </div>

  <div *ngIf="readonly" class="d-flex justify-content-between">
    <button class="btn btn-secondary" (click)="editQuestionnaire()">
      {{ "rspButtonBack" | translate }}
    </button>
    <button
      (click)="goToConfirmPage()"
      routerLinkActive="active"
      class="btn btn-primary"
      id="btn-continue-next-page"
    >
      {{ "rspButtonContinue" | translate }}
    </button>
  </div>
</form>
