<div class="flex-wrapper">
  <div class="center-page">
    <section id="result">
      <header>
        <h1>
          {{ "rspResultHeading" | translate }}
        </h1>
      </header>
      <div *ngIf="showResultToUser">
        <div class="result-item" *ngFor="let result of productResults">
          <section class="card">
            <header class="card-header">
              <h5 class="result-name card-title">
                {{ result.name }}
              </h5>
            </header>
            <section class="card-body">
              <section
                class="rating-item well-sm"
                *ngFor="let rating of result.ratings"
                [class]="'decision-' + rating.id"
              >
                <h6 class="rating-caption">{{ rating.caption }}</h6>
                <div
                  class="rating-details"
                  *ngIf="
                    rating.attributes.length > 0 &&
                    rating.id !== 'Accept' &&
                    showDetails(rating.attributes)
                  "
                >
                  <div class="well">
                    <h6 class="card-subtitle">
                      {{ "rspResultHeadingDecisionDetails" | translate }}
                    </h6>
                    <ul class="list-attributes">
                      <div
                        class="attribute-item"
                        *ngFor="let attribute of rating.attributes"
                      >
                        <!-- Text Attribute -->
                        <li
                          class="text-attr"
                          [class]="attribute.id"
                          *ngIf="attribute.id === 'Text' && attribute.value"
                        >
                          <em>{{ attribute.value }}</em>
                        </li>

                        <!-- Exclusion Clause -->
                        <li
                          class="exclusion-attr"
                          [class]="attribute.id"
                          *ngIf="
                            attribute.id === 'Exclusion' && attribute.value
                          "
                        >
                          <em>{{ attribute.caption }}: </em
                          >{{ attribute.value }}
                        </li>

                        <!-- FlatExtraLoading with Duration -->
                        <div
                          *ngIf="
                            attribute.id === 'FlatExtraDuration' &&
                            attribute.value
                          "
                        >
                          <div
                            *ngFor="let secondAttribute of rating.attributes"
                          >
                            <li
                              class="loading-duration-attr"
                              [class]="secondAttribute.id"
                              *ngIf="
                                secondAttribute.id === 'FlatExtraLoading' &&
                                secondAttribute.value
                              "
                            >
                              <em>{{ secondAttribute.value }}&#x2030;</em> -
                              <em
                                >{{ attribute.value }}
                                {{ "rspMonths" | translate }}</em
                              >
                            </li>
                          </div>
                        </div>

                        <!-- FlatExtraLoading without Duration -->
                        <li
                          class="loading-attr"
                          [class]="attribute.id"
                          *ngIf="
                            (attribute.id === 'FlatExtraLoading' &&
                              attribute.value) ||
                            hasDuration(rating.attributes)
                          "
                        >
                          <em>{{ attribute.caption }}: </em
                          >{{ attribute.value }}&#x2030;
                        </li>

                        <!-- Remaining attributes: EM Loading, Incidence Loading, Premium Loading, Ancillary EM Loading, Extra Premium, Postpone -->
                        <div
                          *ngIf="attribute.value && showAttribute(attribute)"
                        >
                          <!-- Percent -->
                          <li
                            class="loading-attr"
                            [class]="attribute.id"
                            *ngIf="
                              attribute.id === 'AncillaryEMLoading' ||
                              attribute.id === 'EMLoading' ||
                              attribute.id === 'ILoading' ||
                              attribute.id === 'PremiumLoading'
                            "
                          >
                            <em>{{ attribute.caption }}: </em
                            >{{ attribute.value }}&#37;
                          </li>

                          <!-- Duration (Postpone) -->
                          <li
                            class="postpone-duration-attr"
                            [class]="attribute.id"
                            *ngIf="attribute.id === 'PostponeDuration'"
                          >
                            <em>{{ attribute.caption }}: </em
                            >{{ attribute.value }} {{ "rspMonths" | translate }}
                          </li>
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </section>
              <section
                class="decision-default well-sm bg-info"
                *ngIf="result.ratings.length > 0"
              >
                <p>
                  {{ "rspResultDecisionDefault" | translate }}
                </p>
              </section>
            </section>
          </section>
        </div>
      </div>
      <div class="result-info" *ngIf="!showResultToUser">
        <p>{{ "rspResultDefaultInformationText" | translate }}</p>
      </div>
    </section>
    <a
      class="btn btn-primary float-right"
      href="{{ docUrl }}"
      download
      target="_blank"
      rel="noopener"
    >
      {{ "rspResultButtonDownloadPdf" | translate }}
    </a>
  </div>
</div>
